@keyframes flare {
    0% {
        left: -45px;
    }

    20% {
        left: calc(100% + 15px);
    }

    100% {
        left: calc(100% + 15px);
    }
}

.registration {
    background: linear-gradient(85deg, rgba(213, 75, 125, 1) 0%, rgba(255, 0, 92, 1) 100%);
    box-shadow: 0 6px 22px #056dda4d;
    width: 70%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .2s;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
  
}

.registration::before {
    background-color: #fff3;
    bottom: 0;
    content: "";
    position: absolute;
    top: 0;
    transform: skew(-30deg);
    width: 30px;
    will-change: left;
    z-index: 1;
    animation: flare 5s infinite ease;
}