.root {
    position: absolute;
    top: 10px;
    left: 0px;
    filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.40));
}

.wrap {
    position: relative;
    padding-left: 30px;
    padding-top: 2px;
}

.label {
    position: absolute;
    top: 0px;
    left: 0px;
}

.text {
    color: #FFFFFF;
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    font-weight: 400;
    position: relative;
    z-index: 1;
}