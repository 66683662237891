.slick-slide:not(.slick-current) {
  position: relative;
  z-index: 998;
  transform: scale(1);
  transition: 0.25s;
}
.slick-slide.slick-active.slick-current {
  position: relative;
  z-index: 999;
  transform: scale(1.1);
  transition: 0.25s;
}